.banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 555px;
  min-height: 555px;
  color: #F5F5F5;
  overflow: hidden;
  padding: 4rem 2rem;
  margin-top: 80px;
}

.banner-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.watch-live-circle {
  position: absolute;
  bottom: 20px;
  right: 40px;
  width: 200px;
  height: 200px;
  border: 2px solid var(--hover-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hover-color);
  font-size: 24px;
  animation: heartbeat 1.5s infinite;
  cursor: pointer;
}

.banner-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
}

.banner-image-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Light black overlay */
}

.banner-content {
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 80%;
}

.banner-content h1 {
  font-family: Poppins, sans-serif;
  font-size: 64px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.banner-content h1 i {
  font-style: italic;
  font-weight: 300;
}

.banner-content p {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.8;
  margin-bottom: 2rem;
  color: #F5F5F5;
}

.cta-button {
  background-color: #3C4DE0;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 18px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.cta-button:hover {
  background-color: #2a3bb0;
}

.cta-button img {
  margin-left: 1rem;
}

.banner-image-indicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 1;
}

.banner-image-indicator {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.banner-image-indicator.active {
  background-color: white;
}

.hiring-notification {
  position: fixed; /* Change to fixed position */
  top: 100px; /* Position below navbar */
  right: 40px;
  display: flex;
  align-items: center;
  background-color: #1428DA;
  color: #FFFFFF;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999; /* Higher z-index to ensure it's above navbar */
}

.hiring-notification:hover {
  background-color: #0A1FAA;
  transform: scale(1.05);
}

.hiring-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.hiring-text {
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
}

/* Responsive Adjustments */
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


@media (max-width: 992px) {
  .banner {
    height: 400px;
    padding: 2rem;
  }

  .banner-content h1 {
    font-size: 48px;
  }

  .banner-content p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .banner {
    height: 350px;
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .banner-content {
    max-width: 90%;
    text-align: center;
  }

  .banner-content h1 {
    font-size: 36px;
    line-height: 1.2;
  }

  .banner-content p {
    font-size: 16px;
  }

  .hiring-notification {
    position: absolute;
    top: 25%; 
    right: 20px;
    display: flex;
    align-items: center;
    background-color: #1428DA;
    color: #FFFFFF;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .hiring-notification:hover {
    background-color: #0A1FAA;
    transform: scale(1.05);
  }

  .hiring-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .hiring-text {
    font-size: 12px;
  }

  .watch-live-circle {
    width: 150px;
    height: 150px;
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .banner {
    padding: 1.5rem;
    height: 300px;
  }

  .banner-content h1 {
    font-size: 28px;
  }

  .banner-content p {
    font-size: 14px;
  }
}



/* .banner {
  background-color: #0B167C;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 2rem;
  color: #F5F5F5;
  height: 555px;
  min-height: 555px;
}

.banner-content {
  flex: 1;
  max-width: 50%;
}

.banner-content h1 {
  font-family: Poppins, sans-serif;
  font-size: 64px;
  font-weight: 500;
  line-height: 96px;
  text-align: left;
  margin-bottom: 1rem;
}

.banner-content h1 i {
  font-style: italic;
  font-weight: 300;
  font-size: 58px;
}

.banner-content p {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-bottom: 2rem;
  color: #F5F5F5;
}

.cta-button {
  background-color: #3C4DE0;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.cta-button img {
  margin-left: 1rem;
}

.banner-image {
  margin-right: -90px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


.banner-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
}

.banner-image-indicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.banner-image-indicator {
  width: 10px;
  height: 10px;
  background-color: rgba(255,255,255,0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.banner-image-indicator.active {
  background-color: white;
}



.banner-image img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1200px) {
  .banner-content h1 {
    font-size: 48px;
    line-height: 72px;
  }
}

@media (max-width: 992px) {
  .banner {
    padding: 8rem 1.5rem;
    height: 400px;
  }

  .banner-content h1 {
    font-size: 36px;
    line-height: 54px;
  }

  .banner-content p {
    font-size: 16px;
    line-height: 28px;
  }

  .banner-image {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .banner {
    flex-direction: column;
    height: auto;
    padding: 2rem 1rem;
  }

  .banner-content {
    max-width: 100%;
    text-align: center;
  }

  .banner-content h1 {
    font-size: 36px;
    line-height: 1.2;
  }

  .banner-image {
    margin-right: 0;
    margin-top: 2rem;
  }
}

@media (max-width: 576px) {
  .banner {
    flex-direction: column;
    text-align: center;
    padding: 8rem 1.5rem;
  }

  .banner-image {
    display: none;
  }

  .banner-content {
    max-width: 100%;
  }

  .banner-content h1 {
    font-size: 28px;
    line-height: 42px;
  }

  .banner-content p {
    font-size: 14px;
    line-height: 24px;
  }
} */