/* Modal Overlay */
.property-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

/* Modal Container */
.property-modal-content {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 28rem; /* equivalent to max-w-md */
}

/* Modal Header */
.property-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.property-modal-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

/* Close Button */
.property-close-button {
  padding: 0.25rem;
  border-radius: 9999px;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-close-button:hover {
  background-color: #f3f4f6;
}

/* Modal Body */
.property-modal-body {
  padding: 1.5rem;
}

/* Form Container */
.property-form-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Form Items */
.property-form-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Download Button */
.property-download-button {
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  background-color: #dbeafe;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.property-download-button:hover {
  background-color: #bfdbfe;
}

/* Text Labels */
.property-form-item span {
  color: #374151;
}

/* Alert Styles */
.property-alert {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
}

.property-alert-error {
  color: #dc2626;
}

.property-alert-message {
  margin: 0;
  font-size: 0.875rem;
}


/* .property-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .property-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    text-align: center;
  }
  
  .property-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .property-modal-header h2 {
    margin: 0;
  }
  
  .property-close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .property-modal-body {
    display: flex;
    justify-content: center;
  }
  
  .property-form-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .property-form-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .property-circle-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #3C4DE0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .property-download-icon {
    font-size: 24px;
    color: #3C4DE0;
  } */